<template>
  <div>
    <p>
      {{ lang[activeLang]['Each complaint is reviewed by staff to determine whether it constitutes a civil liberties problem the ACLU-NC may be able to help. There are many factors that go into determining whether we may be of assistance at any given time, including availability of staff, resources and timing. If the ACLU-NC is able to offer you assistance after your initial intake, they will contact you to gather more information about your situation. If your situation is time sensitive, please continue to look for help elsewhere while we review your complaint. Your local bar association may have a lawyer referral service or similar program that may provide you with attorney referrals in your county. Please refer to the'] }} <a
        href="http://www.calbar.ca.gov/Public/LawyerReferralServicesLRS.aspx"
        target="_blank"
      > {{ lang[activeLang]['California Bar Association'] }} </a> {{ lang[activeLang]['website for more information. If the ACLU-NC is not able to offer you assistance, we will similarly contact you by phone, mail or email. In either case, because of our small size and the large volume of complaints that we receive, it will take at least a few weeks to let you know of our initial decision.'] }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'Process',
  props: {
    activeLang: {
      type: String,
      default: 'English',
    },
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>


<style scoped>

p {
  font-size: 18px;
  margin: 0 1em;
}

</style>

