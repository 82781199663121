<template>
  <div class="form-field">
    <div class="input-container">
      <DatePicker
        v-model="date"
        :enable-time-picker="false"
        text-input
        utc
        auto-apply
        :placeholder="input.title"
      />
    </div>
    <label
      v-if="input.hasError && input.requiredError"
      class="error-text"
    >
      {{ lang[activeLang].required_error }}</label>
    <label
      v-else-if="input.hasError && input.errorMsg"
      class="error-text"
    >{{ input.errorMsg }}</label>
  </div>
</template>

<script>
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'FormDatePicker',
  components: {
    DatePicker,
  },
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'EN'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      date: null,
    };
  },
  watch: {
    date: function(newDate) {
      this.inputUpdated(this.input, newDate)
    }
  },
  created() {
    // If there's no stored data skip updating picked.
    if (this.formData[this.input.index]) {
      // console.log(this.formData[this.input.index])
      this.date = new Date(this.formData[this.input.index])
      // this.date = new Date(parseInt(this.formData[this.input.index]) * 1000)
      // console.log(this.date)
    }

    this.inputUpdated(this.input, this.date)
  },
  methods: {
    inputUpdated(input, newVal) {
      // Send new value up to the parent
      // newVal = Math.floor(newVal.getTime() / 1000)
      // console.log(newVal)
      // console.log("ACTUAL DATE: ", new Date(newVal * 1000))
      this.$emit('formUpdate', newVal)
      // If there's no error then return
      if (!input.hasError) return

      // If there's now a value then we're going to disable the required error.
      if (newVal.length > 0) {
        input.requiredError = false
      }

      // Perform a valid check to see if the input is now valid.
      if (input.errorMsg && input.isValid && input.isValid(newVal)) {
        input.errorMsg = null
      }

      // If there's no error message or requiredError then we're going to remove the error flag entirely.
      if (!input.errorMsg || input.requiredError) {
        input.hasError = false
      }
    }
  }
}
</script>

<style>
.input-container .dp__input {
  border: 2px solid #000;
  border-radius: 0;
  padding-top: 11px;
  padding-bottom: 11px;
}
</style>
