<template>
  <div class="container-main">
    <div
      v-if="debugging"
      class="debugging-container"
    >
      <pre v-if="JSONData">
      <code>
        {{ JSONData }}
      </code>
      </pre>
      <h1 style="color: rgb(214, 88, 88)">
        DEBUGGING MODE ENABLED.
      </h1>
      <div
        class="debug-button"
        @click="clearFormData"
      >
        <label class="submit-button-text"> CLEAR LOCAL DATA </label>
      </div>
      <div
        class="debug-button"
        @click="submitForm"
      >
        <label class="submit-button-text"> TEST SUBMIT </label>
      </div>
    </div>
    <FormCompleteHeader
      v-if="submitted === true"
      id="FormCompleteHeader"
      :active-lang="activeLang"
    />
    <FormHomeHeader
      v-else-if="!isLocationValid"
      :active-lang="activeLang"
    />
    <FormFileHeader
      v-else-if="isLocationValid"
      id="FormFileHeader"
      :active-lang="activeLang"
    />
    <label
      v-if="formError === true"
      id="error-notice"
    >
      {{ lang[activeLang].review_error }}</label>
    <Loading v-if="loading" />
    <div v-if="submitted != true && !loading">
      <slot v-for="(formSection, index) in formStructure">
        <form-section
          v-if="
            typeof formSection.shouldShow === 'undefined' ||
              (typeof formSection.shouldShow != 'undefined' &&
                formSection.shouldShow() === true)
          "
          :id="index"
          :key="index"
          :title="index"
          :form-section="formSection"
          :active-lang="activeLang"
          :form-data="formData"
          @formUpdate="formFieldChanged"
        />
      </slot>
      <div
        v-if="isLocationValid"
        class="submit-button"
        @click="submitForm"
      >
        <label class="submit-button-text">
          {{ lang[activeLang].submit }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from './FormSection.vue'
import FormHomeHeader from './FormHomeHeader.vue'
import FormFileHeader from './FormFileHeader.vue'
import FormCompleteHeader from './FormCompleteHeader.vue'
import Loading from './Loading.vue'
import formRequest from "../api.js"

function isValidEmail(email) {
  // Regular expression taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
  if (!re.test(email)) {
    return false
  }
  return true
}

function isValidPhoneNumber(number) {
  const re = /^(\+\d+)?\s*\(?\d+\)?[\s.-]?\d*[\s.-]?\d*$/
  if (!re.test(number)) {
    return false
  }
  return true
}

function isValidZipCode(zipCode) {
  const re = /^\d{5}(?:[-\s]\d{4})?$/
  if (!re.test(zipCode)) {
    return false
  }
  return true
}

export default {
  name: 'InquiryForm',
  components: {
    FormSection,
    FormHomeHeader,
    FormCompleteHeader,
    FormFileHeader,
    Loading
  },
  props: {
    activeLang: {
      type: String,
      default: localStorage.activeLang || 'English',
    },
  },
  data() {
    return {
      formData: JSON.parse(localStorage.formData || '{}'),
      erroredFields: [],
      JSONData: null,
      submitted: false,
      formError: false,
      debugging: false,
      loading: true,
      formStructure: {},
    }
  },
  computed: {
    isLocationValid() {
      return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No')
    }
  },
  watch:{
    activeLang() {
      location.reload();
    }
  },
  async beforeMount() {
    this.loading = true
    try {
      await formRequest.requestFormData()
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  mounted() {
    // Build form inputs here.

    /* 
      INCIDENT LOCATION
    */
    this.addSection('location_select')

    this.addToRow('location_select', 0, 'location_notice', {
      lines: this.lang[this.activeLang].location_select.notice,
      type: 'notice',
    })

    this.addToRow('location_select', 1, 'incident_in_state', {
      title: this.lang[this.activeLang].location_select.county_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true,
    })

    this.addToRow('location_select', 2, 'visit_affiliates_notice', {
      lines: this.lang[this.activeLang].location_select.county_select.error.notInState.notice,
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'No'
      },
    })

    this.addToRow('location_select', 3, 'visit_affiliates', {
      title: this.lang[this.activeLang].location_select.county_select.error.notInState.title,
      type: 'button',
      url: this.lang[this.activeLang].location_select.county_select.error.notInState.url,
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'No'
      },
    })

    this.addToRow('location_select', 4, 'covid_related', {
      title: this.lang[this.activeLang].location_select.covid_select.title,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })

    /* 
      CONTACT INFORMATION SECTION
    */
    this.addSection('contact_info', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No');
      },
    })

    /* 
      AFFECTED CONTACT INFORMATION
    */
    this.addToRow('contact_info', 0, 'affected_person', {
      title: this.lang[this.activeLang].contact_info.self_select.title,
      type: 'select',
      options: ['Myself', 'Someone else'],
      optionsObjects: this.lang[this.activeLang].contact_info.self_select.options,
      required: true,
      fullwidth: true,
    })

    this.addToRow('contact_info', 1, 'filer_relationship', {
      title: this.lang[this.activeLang].contact_info.relationship_select.title,
      type: 'select',
      options: ['Relative', 'Spouse', 'Friend'],
      optionsObjects: this.lang[this.activeLang].contact_info.relationship_select.options,
      required: true,
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 2, 'affected_notice', {
      lines: this.lang[this.activeLang].contact_info.affected_notice,
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 3, 'affected_salutation', {
      title: this.lang[this.activeLang].contact_info.salutation,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_salutations.options,
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_salutations.options[0]
      },
    })

    this.addToRow('contact_info', 3, 'affected_firstname', {
      title: this.lang[this.activeLang].contact_info.a_first_name,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
      required: true,
    })

    this.addToRow('contact_info', 3, 'affected_lastname', {
      title: this.lang[this.activeLang].contact_info.a_last_name,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
      required: true,
    })

    this.addToRow('contact_info', 4, 'affected_pronoun', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.title,
      type: 'select',
      options: formRequest.apiData.get_pronouns.options,
      optionsObjects: this.lang[this.activeLang].contact_info.pronoun_select.options,
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_pronouns.options[0]
      },
    })

    this.addToRow('contact_info', 4, 'affected_pronoun_typed', {
      title: 'Please Input a Gender Pronoun',
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_pronoun') === 'Other'
      },
    })

    this.addToRow('contact_info', 4, 'affected_ethnicity', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.title,
      type: 'select',
      options: formRequest.apiData.get_ethnicities.options,
      optionsObjects: this.lang[this.activeLang].contact_info.ethnic_select.options,
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_ethnicities.options[0]
      },
    })

    this.addToRow('contact_info', 5, 'affected_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      required: this.getFormData('affected_person') === 'Myself',
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 5, 'affected_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 6, 'affected_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      required: this.getFormData('affected_person') === 'Myself',
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 6, 'affected_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_states.options,
      required: this.getFormData('affected_person') === 'Myself',
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 6, 'affected_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      required: this.getFormData('affected_person') === 'Myself',
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 7, 'affected_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      required: this.getFormData('affected_person') === 'Myself',
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      },
    })

    this.addToRow('contact_info', 7, 'affected_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      required: this.getFormData('affected_person') === 'Myself',
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person')
      }
    })

    this.addToRow('contact_info', 7, 'affected_prison_name', {
      title: this.lang[this.activeLang].contact_info.prison_select.title,
      type: 'select',
      options: formRequest.apiData.get_prisons.options,
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_prisons.options[0]
      },
    })

    this.addToRow('contact_info', 7, 'affected_prison_number', {
      title: this.lang[this.activeLang].contact_info.p_number,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') && formRequest.apiData.get_prisons.options[0]
      },
    })

    /* 
      FILER CONTACT INFORMATION
    */
    this.addToRow('contact_info', 8, 'filer_notice', {
      lines: this.lang[this.activeLang].contact_info.filer_notice,
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 9, 'filer_salutation', {
      title: this.lang[this.activeLang].contact_info.salutation,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_salutations.options,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && formRequest.apiData.get_salutations.options[0]
      },
    })

    this.addToRow('contact_info', 9, 'filer_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 9, 'filer_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 10, 'filer_pronoun', {
      title: this.lang[this.activeLang].contact_info.pronoun_select.title,
      type: 'select',
      options: formRequest.apiData.get_pronouns.options,
      optionsObjects: this.lang[this.activeLang].contact_info.pronoun_select.options,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && formRequest.apiData.get_pronouns.options[0]
      },
    })

    this.addToRow('contact_info', 10, 'filer_pronoun_typed', {
      title: 'Please Input a Gender Pronoun',
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_pronoun') === 'Other'
      },
    })

    this.addToRow('contact_info', 10, 'filer_ethnicity', {
      title: this.lang[this.activeLang].contact_info.ethnic_select.title,
      type: 'select',
      options: formRequest.apiData.get_ethnicities.options,
      optionsObjects: this.lang[this.activeLang].contact_info.ethnic_select.options,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && formRequest.apiData.get_ethnicities.options[0]
      },
    })

    this.addToRow('contact_info', 11, 'filer_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 11, 'filer_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 12, 'filer_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      thirdwidth: true,
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 12, 'filer_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_states.options,
      thirdwidth: true,
      required: true,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 12, 'filer_zip', {
      title: this.lang[this.activeLang].contact_info.zip_ik,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 13, 'filer_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      required: true,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 13, 'filer_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      required: true,
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else'
      },
    })

    this.addToRow('contact_info', 14, 'filer_prison_name', {
      title: this.lang[this.activeLang].contact_info.prison_select.title,
      type: 'select',
      options: formRequest.apiData.get_prisons.options,
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && formRequest.apiData.get_prisons.options[0]
      },
    })

    this.addToRow('contact_info', 14, 'filer_prison_number', {
      title: this.lang[this.activeLang].contact_info.p_number,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('affected_person') === 'Someone else' && formRequest.apiData.get_prisons.options[0]
      },
    })

    /* 
      COMPLAINT AGAINST
    */
    this.addSection('complaint_against', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No')
      },
    })

    this.addToRow('complaint_against', 0, 'against_salutation', {
      title: this.lang[this.activeLang].contact_info.salutation,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_salutations.options,
      shouldShow: () => {
        return formRequest.apiData.get_salutations.options[0]
      }
    })

    this.addToRow('complaint_against', 0, 'against_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
    })

    this.addToRow('complaint_against', 0, 'against_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
    })

    this.addToRow('complaint_against', 1, 'against_agency', {
      title: this.lang[this.activeLang].contact_info.agency,
      type: 'text',
      fullwidth: true
    })

    this.addToRow('complaint_against', 2, 'against_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
    })

    this.addToRow('complaint_against', 2, 'against_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
    })

    this.addToRow('complaint_against', 3, 'against_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      thirdwidth: true
    })

    this.addToRow('complaint_against', 3, 'against_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_states.options,
      thirdwidth: true
    })

    this.addToRow('complaint_against', 3, 'against_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error;
        }
      },
    })

    this.addToRow('complaint_against', 4, 'against_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      fullwidth: true,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
    })

    this.addToRow('complaint_against', 5, 'against_affected_permission', {
      title: this.lang[this.activeLang].complaint_against.contact_select,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      fullwidth: true
    })

    /* 
      WITNESS
    */
    this.addSection('witness', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No')
      },
    })

    this.addToRow('witness', 0, 'witness_salutation', {
      title: this.lang[this.activeLang].contact_info.salutation,
      type: 'select',
      fullwidth: true,
      options: formRequest.apiData.get_salutations.options,
      shouldShow: () => {
        return formRequest.apiData.get_salutations.options[0]
      }
    })

    this.addToRow('witness', 0, 'witness_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
    })

    this.addToRow('witness', 0, 'witness_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
    })

    this.addToRow('witness', 1, 'witness_agency', {
      title: this.lang[this.activeLang].contact_info.agency,
      type: 'text',
      fullwidth: true
    })

    this.addToRow('witness', 2, 'witness_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
    })

    this.addToRow('witness', 2, 'witness_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
    })

    this.addToRow('witness', 3, 'witness_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      thirdwidth: true
    })

    this.addToRow('witness', 3, 'witness_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_states.options,
      thirdwidth: true
    })

    this.addToRow('witness', 3, 'witness_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error;
        }
      },
    })

    this.addToRow('witness', 4, 'witness_phone', {
      title: this.lang[this.activeLang].contact_info.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      fullwidth: true,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
    })

    this.addToRow('witness', 5, 'witness_affected_permission', {
      title: this.lang[this.activeLang].witness.contact_select,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      fullwidth: true
    })

    /* 
      OTHER AGENCY COMPLAINT
    */
    this.addSection('other_complaint', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No')
      },
    })
    this.addToRow('other_complaint', 0, 'other_complaint_select', {
      title: this.lang[this.activeLang].other_complaint.complaint_select,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('other_complaint', 1, 'reported_agency_names', {
      title: this.lang[this.activeLang].other_complaint.details,
      type: 'textarea',
      charlimit: 1000,
      shouldShow: () => {
        return this.getFormData('other_complaint_select') == 'Yes'
      },
    })

    /* 
      ATTORNEY INFORMATION
    */
    this.addSection('attorney', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No')
      },
    })
    this.addToRow('attorney', 0, 'has_attorney', {
      title: this.lang[this.activeLang].attorney.attorney_select,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('attorney', 1, 'attorney_firstname', {
      title: this.lang[this.activeLang].contact_info.first_name,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 1, 'attorney_lastname', {
      title: this.lang[this.activeLang].contact_info.last_name,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 2, 'attorney_agency_name', {
      title: this.lang[this.activeLang].attorney.firm_name,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 3, 'attorney_address', {
      title: this.lang[this.activeLang].contact_info.address,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 3, 'attorney_address2', {
      title: this.lang[this.activeLang].contact_info.address_2,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 4, 'attorney_city', {
      title: this.lang[this.activeLang].contact_info.city,
      type: 'text',
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 4, 'attorney_state', {
      title: this.lang[this.activeLang].contact_info.state,
      type: 'select',
      options: formRequest.apiData.get_states.options,
      thirdwidth: true,
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 4, 'attorney_zip', {
      title: this.lang[this.activeLang].contact_info.zip,
      error: this.lang[this.activeLang].contact_info.error.zip,
      type: 'text',
      thirdwidth: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 5, 'attorney_phone', {
      title: this.lang[this.activeLang].attorney.phone,
      error: this.lang[this.activeLang].contact_info.error.phone,
      type: 'text',
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return this.error
        }
      },
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    this.addToRow('attorney', 5, 'attorney_email', {
      title: this.lang[this.activeLang].contact_info.email,
      error: this.lang[this.activeLang].contact_info.error.email,
      type: 'text',
      isValid(email) {
        if (!isValidEmail(email)) {
          return this.error;
        }
      },
      shouldShow: () => {
        return this.getFormData('has_attorney') == 'Yes'
      },
    })

    /* 
      PENDING LAWSUITS
    */
    this.addSection('pending_suit', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No')
      },
    })
    this.addToRow('pending_suit', 0, 'pending_suit_select', {
      title: this.lang[this.activeLang].pending_suit.pending_select,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      required: true,
      fullwidth: true
    })

    this.addToRow('pending_suit', 1, 'lawsuit_case_number', {
      title: this.lang[this.activeLang].pending_suit.case_no,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('pending_suit_select') == 'Yes'
      },
    })

    this.addToRow('pending_suit', 1, 'lawsuit_case_name', {
      title: this.lang[this.activeLang].pending_suit.case_title,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('pending_suit_select') == 'Yes'
      },
    })

    this.addToRow('pending_suit', 2, 'lawsuit_file_date', {
      title: this.lang[this.activeLang].pending_suit.date,
      type: 'datepicker',
      required: true,
      shouldShow: () => {
        return this.getFormData('pending_suit_select') == 'Yes'
      },
    })

    this.addToRow('pending_suit', 2, 'lawsuit_jurisdiction', {
      title: this.lang[this.activeLang].pending_suit.jurisdiction,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('pending_suit_select') == 'Yes'
      },
    })

    this.addToRow('pending_suit', 2, 'lawsuit_judge', {
      title: this.lang[this.activeLang].pending_suit.judge,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('pending_suit_select') == 'Yes'
      },
    })

    this.addToRow('pending_suit', 2, 'lawsuit_opp_counsel', {
      title: this.lang[this.activeLang].pending_suit.opp_counsel,
      type: 'text',
      shouldShow: () => {
        return this.getFormData('pending_suit_select') == 'Yes'
      },
    })

    this.addToRow('pending_suit', 2, 'lawsuit_status', {
      title: this.lang[this.activeLang].pending_suit.status,
      type: 'text',
      fullwidth: true,
      shouldShow: () => {
        return this.getFormData('pending_suit_select') == 'Yes'
      },
    })

    /* 
      INCIDENT INFORMATION
    */
    this.addSection('incident', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No')
      },
    })

    this.addToRow('incident', 0, 'incident_date', {
      title: this.lang[this.activeLang].incident.date_select,
      type: 'datepicker',
      required: true,
    })

    this.addToRow('incident', 0, 'incident_county', {
      title: this.lang[this.activeLang].contact_info.county_ik,
      type: 'select',
      options: formRequest.apiData.get_counties.options,
      shouldShow: () => {
        return formRequest.apiData.get_counties.options[0]
      }
    })

    this.addToRow('incident', 1, 'incident_reason', {
      title: this.lang[this.activeLang].incident.what_happened,
      type: 'textarea',
      charlimit: 1000,
      required: true,
    })

    this.addToRow('incident', 2, 'incident_resolution', {
      title: this.lang[this.activeLang].incident.to_do,
      type: 'textarea',
      charlimit: 1000,
    })

    this.addToRow('incident', 3, 'incident_explanation', {
      title: this.lang[this.activeLang].incident.explanation,
      type: 'textarea',
      charlimit: 1000,
    })

    this.addToRow('incident', 4, 'incident_why_did_this_happen', {
      title: this.lang[this.activeLang].incident.why_did_this_happen,
      type: 'textarea',
      charlimit: 1000
    })

    this.addToRow('incident', 5, 'incident_resolve_attempt', {
      title: this.lang[this.activeLang].incident.resolve_attempt,
      type: 'textarea',
      charlimit: 1000,
    })

    this.addToRow('incident', 6, 'incident_plaintiff_select', {
      title: this.lang[this.activeLang].incident.plaintiff_select,
      type: 'select',
      options: ['Yes', 'No'],
      optionsObjects: this.lang[this.activeLang],
      fullwidth: true
    })

    /* 
      DISCLAIMER AND NOTICE.
    */
    this.addSection('disclaimer', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes' && (this.getFormData('covid_related') == 'Yes' || this.getFormData('covid_related') == 'No')
      },
    })

    this.addToRow('disclaimer', 0, 'disclaimer_notice', {
      lines: this.lang[this.activeLang].disclaimer.notice,
      type: 'notice',
    })
  },
  methods: {
    formFieldChanged(fieldID, val) {
      // Storing the values into local storage so data is maintained across page navigations.
      this.formData[fieldID] = val
      localStorage.formData = JSON.stringify(this.formData)
    },
    clearFormData() {
      delete localStorage.formData
    },
    async submitForm() {
      // Make sure the form is valid before properly submitting
      const isValid = this.isFormValid()
      this.JSONData = JSON.stringify(this.formData, null, 2)
      if (!isValid) {
        this.formError = true
        // Loop over all erroredFields and find one that has an error.
        for (let i = 0; i < this.erroredFields.length; i++) {
          let input = this.erroredFields[i]
          if (!input.hasError) {
            // Remove it from the array since it's no longer relevent
            this.erroredFields.splice(i, 1)
            continue
          }

          // Scroll to errored element for user to see
          let elmnt = document.getElementById(input.index)
          elmnt.scrollIntoView({ block: 'center' })
          break
        }
      } else {
        const status = await formRequest.submitForm(this.JSONData)
        if (status === 200) {
          this.submitted = true
          this.formError = false      
        }
        // Do not delete stored data if debugging.
         if (!this.debugging) delete localStorage.formData
      }
    },
    addSection(title, sectionData) {
      this.formStructure[title] = sectionData || {}
    },
    addToRow(section, rowID, index, content) {
      content.section = section
      content.index = index
      // Add a content to field to the section if it does not exist already.
      this.formStructure[section].content =
        this.formStructure[section].content || []
      // Create a new row to the section content if it does not exist already.
      this.formStructure[section].content[rowID] =
        this.formStructure[section].content[rowID] || {}
      // Finally add the content to the section, content, row.
      this.formStructure[section].content[rowID][index] = content
    },
    getFormData(index) {
      // Alternative to indexing form data.
      return this.formData[index]
    },
    getInputError(index, input) {
      let showingSection = this.formStructure[input.section].shouldShow

      // If the section is not visible, it is not required to have a value.
      if (showingSection && !showingSection()) return false

      // If the input is not visible, it is not required to have a value.
      let showingInput = input.shouldShow
      if (showingInput && !showingInput()) {
        return false
      }

      // If the input is visible and is not required and is empty we're not gonna perform any valid checks.
      if (!input.required && !this.formData[index]) return false

      // If the input is required and is not inside the formData, then they skipped this input.
      if (!this.formData[index] || this.formData[index] === '') {
        input.hasError = true
        input.requiredError = true
        return true
      }

      // Finally perform valid checks such as email, zip or phone number.
      if (input.isValid) {
        let errorMsg = input.isValid(this.formData[input.index])
        if (errorMsg) {
          input.hasError = true
          input.errorMsg = errorMsg
          return true
        }
      }
    },
    isFormValid() {
      // TODO: Refactor formStructure with a better data structure.
      let isValid = true
      this.errorFields = []
      // eslint-disable-next-line no-unused-vars
      for (let [title, section] of Object.entries(this.formStructure)) {
        for (let inputs of section.content) {
          for (let [inputIndex, input] of Object.entries(inputs)) {
            // Reset any previous errors when performing the form valid check.
            input.hasError = false
            if (this.getInputError(inputIndex, input)) {
              isValid = false
              this.erroredFields.push(input)
            }
          }
        }
      }
      return isValid
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lang-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#error-notice {
  width: 100%;
  color: rgb(214, 88, 88);
  text-align: left;
  font-size: 16px;
  padding-bottom: 20px;
  float: left;
  text-decoration: underline;
}

.debug-button {
  background-color: rgb(214, 88, 88);
  padding: 10px;
  margin: 20px 25%;
  cursor: pointer;
  border-radius: 5px;
}

.container {
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

.submit-button {
  background-color: #fbaf17;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0px;
  margin: 30px 25% 120px 25%;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.4rem;
}

.submit-button-text {
  pointer-events: none;
}

label {
  color: #fff;
}
</style>
