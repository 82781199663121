<template>
  <div
    class="form-field"
    :class="{ fullwidth: input.fullwidth, thirdwidth: input.thirdwidth }"
  >
    <div class="input-container">
      <input
        :id="inputKey"
        v-model="message"
        type="text"
        :placeholder="input.title"
        class="floating__input"
        :class="{ hasError: input.hasError }"
        @input="inputUpdate(input, $event.target.value)"
      >
      <label
        class="floating__label"
        :for="inputKey"
        :data-content="input.title"
      >{{ input.title }}<em v-if="input.required">*</em></label>
    </div>
    <label
      v-if="input.hasError && input.requiredError"
      class="error-text"
    >
      {{ lang[activeLang].required_error }}</label>
    <label
      v-else-if="input.hasError && input.errorMsg"
      class="error-text"
    >{{ input.errorMsg }}</label>
  </div>
</template>

<script>
export default {
  name: 'FormText',
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      message: String
    }
  },
  created() {
    // Update current message from formData which gets updated from localStorage.
    this.message = this.formData[this.input.index] || null
  },
  methods: {
    inputUpdate: function(input, newVal) {
      // Send the new value up to the parent.
      this.$emit('formUpdate', newVal)

      // If there's no error, don't perform a valid check to see if it has updated.
      if (!input.hasError) return

      // If the length increases, then it's no longer empty and we'll remove the requiredError.
      if (newVal.length > 0) {
        input.requiredError = false
      }

      // If it had a valid error we'll validate one more time.
      if (input.errorMsg && input.isValid && input.isValid(newVal)) {
        input.errorMsg = null
      }

      // If there's no validation or required error we remove the hasError property.
      if (!input.errorMsg || input.requiredError) {
        input.hasError = false
      }
    }
  }
}
</script>



<style>
.input-container.input-field {
  padding-top: 0;
}

em {
  background-color: white;
}

.form-field {
  width: 100%;
  /*margin: 1em 10px 0 10px;*/
  margin-top: 1em;
}

.fullwidth {
  flex-basis: 100%;
}

.input-container {
  /* padding-top: .5em; */
  position: relative;
  font-size: 1rem;
}

.floating__input {
  border-radius: 0px;
  border: 2px solid #000;
  outline-width: 1px;
  padding: 18px 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1em;
  caret-color: #9cbaf2;
  width: 100%;
  text-indent: 1em;
  box-sizing: border-box;
}

@media screen and (min-width: 900px) {
  .floating__input {
    padding: 14px 0;
  }

  .form-field {
    width: 48%;
  }

  .thirdwidth {
    width: 31%;
  }
}

.floating__input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.floating__label {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  position: absolute;
  font-weight: 600;
  margin-left: 1em;
  color: rgb(102, 102, 102);
  content: attr(data-content);
  filter: blur(0);
  backface-visibility: hidden;
  pointer-events: none;
  display: inline-block;
  background: #fff;
  transition: all 0.2s ease;
  text-align: left;
}

.floating__input:-webkit-autofill + .floating__label,
.floating__input:-webkit-autofill:hover + .floating__label,
.floating__input:-webkit-autofill:focus + .floating__label,
.floating__input:not(:placeholder-shown) + .floating__label,
.floating__input:focus + .floating__label {
  color: rgb(88, 147, 214);
  font-size: 0.84em;
  top: -0.5em;
  transform: translateY(0%);
  margin-top: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  border: 1px solid #d4d6de;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

input:focus {
  border-color: #9cbaf2;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.error-text {
  color: rgb(214, 88, 88);
  text-align: left;
  font-size: 12px;
  padding-top: 4px;
  float: left;
}

.hasError {
  border-color: rgb(214, 88, 88);
}

em {
  color: rgb(214, 88, 88);
}
</style>
