<template>
  <div class="notice-field">
    <p 
      v-if="!Array.isArray(input.lines)" 
      class="form-notice-text"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="input.lines" />
      <!--eslint-enable-->
    </p>
    <p 
      v-for="(line, index) in input.lines"
      v-else
      :key="index"
      class="form-notice-text"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="line" />
      <!--eslint-enable-->
    </p>
  </div>
</template>

<script>
export default {
  name: 'FormNotice',
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.notice-field {
  width: 100%;
  margin-top: 1em;
  color: rgb(102, 102, 102);
  height: 100%;
  text-align: left;
  background: #f3f3f3;
  padding: 15px;
  border: 2px solid #ebebeb;
  line-height: 1.7;
}

.form-notice-text {
  margin: 0;
}

.form-notice-text:not(:last-child){
  margin-bottom: 15px;
}
</style>
