import { createApp } from 'vue'
import App from './App.vue'
import english from './translations/english.json'
import spanish from './translations/spanish.json'
const app = createApp(App)
app.config.globalProperties.lang = {
  'English': english,
  'Spanish': spanish
}

app.mount('#app')
